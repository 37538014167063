export default {
  frenchArticlesArray: Object.freeze([
    'et',
    'ou',
    'avec',
    'où',
    'en',
    'dans',
    'pour',
    'par',
    'à',
    'au',
    'aux',
    'mais',
    'donc',
    'dont',
    'or',
    'ni',
    'la',
    'le',
    'les',
    'un',
    'une',
    'des',
    'de',
    'du',
    'je',
    'tu',
    'il',
    'elle',
    'on',
    'nous',
    'vous',
    'ils',
    'elles',
    'eux',
    'ce',
    'celle',
    'cela',
    'ceci',
    'ceux',
    'celles',
    'ces',
    'son',
    'si',
    'sa',
    'ses',
    'nos',
    'vos',
    'leur',
    'leurs',
    'mon',
    'ma',
    'mes',
  ]),

  englishArticlesArray: Object.freeze([
    'and',
    'by',
    'where',
    'when',
    'with',
    'from',
    'but',
    'then',
    'than',
    'or',
    'on',
    'in',
    'of',
    'for',
    'if',
    'to',
    'off',
    'the',
    'a',
    'at',
    'an',
    'my',
    'me',
    'you',
    'your',
    'he',
    'his',
    'she',
    'her',
    'it',
    'its',
    'we',
    'us',
    'they',
    'their',
    'this',
    'there',
  ]),
}
