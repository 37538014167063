const selectLangOptions = [
  {
    text: 'FR',
    option: 'Français',
    value: 'fr',
  },
  {
    text: 'EN',
    option: 'English',
    value: 'en',
  },
]
export default selectLangOptions
